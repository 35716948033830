import React from 'react';

const Finance = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Finanças</h2>
      <p className="text-gray-400">Rastreamento e gerenciamento financeiro em breve...</p>
    </div>

  );
};

export default Finance; 